var render = function render(){var _vm=this,_c=_vm._self._c;return _c('baidu-map',{staticClass:"bm-view",attrs:{"center":_vm.center,"zoom":_vm.zoom,"scroll-wheel-zoom":true},on:{"ready":_vm.handler}},_vm._l((_vm.communitList),function(item){return _c('bm-marker',{key:item.id,attrs:{"position":{ lng: item.lon, lat: item.lat },"icon":{
        url: require('@/assets/images/indexCon/map_point_green.png'),
        size: { width: 24, height: 24 },
      }}},[_c('bm-label',{attrs:{"content":item.community_name,"labelStyle":{
          color: '#fff',
          fontSize: '18px',
          background: '',
          border: 0,
        },"offset":{ width: -15, height: 20 }}})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }