import request from '@/utils/request'

function getCommunit(data){	//社区坐标列表
 return request({
    url: '/api/front/community_list',
    method: 'post',
    data: data ,
 })
}
function dutyUser(data){	//值班人员列表
 return request({
    url: '/api/front/on_duty_user_list',
    method: 'post',
    data: data ,
 })
}


export default {
   getCommunit,
	dutyUser,
}