<template>
  <div class="childPage">
    <div class="topTitle">安防监控</div>
    <div class="concent">
      <div class="topCon">
        <div class="chart-left">
          <myLeftPieChart></myLeftPieChart>
          <!-- <div class="data">
            <p class="allNum">1042</p>
            <p class="item1">告警设备 32%</p>
            <p class="item2">总设备 68%</p>
          </div> -->
        </div>
        <div class="chart-right">
          <myRightPieChart></myRightPieChart>
        </div>
      </div>
      <div class="bottomCon">
        <div class="barChart">
          <myBarChart></myBarChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myLeftPieChart from "./components/SecurityMonitoring/leftPieChart.vue";
import myRightPieChart from "./components/SecurityMonitoring/rightPieChart.vue";
import myBarChart from "./components/SecurityMonitoring/barChart.vue";
export default {
  components: { myLeftPieChart, myRightPieChart, myBarChart },
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
.childPage {
  position: relative;
  width: 100%;
  height: 100%;
  .topTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 264px;
    height: 31px;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
  }
  .concent {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    .topCon {
      display: flex;
      width: 100%;
      height: 160px;
      padding-left: 20px;
      //   background-color: #fff;
      .chart-left {
        // position: relative;
        width: 50%;
        height: 100%;
        // .data {
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   width: 100%;
        //   height: 100%;
        // }
      }
      .chart-right {
        width: 50%;
        height: 100%;
      }
    }
    .bottomCon {
      width: 100%;
      height: 200px;
      //   background-color: #f00;
      .barChart {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
