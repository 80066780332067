import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './scss/element-ui-reset.scss'

import * as echarts from 'echarts';
import global from '../global.js';

import tracking from 'tracking'
//图片点击放大预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
//等比缩放自适应
import myFrame from './components/Common/Frame.vue'
import boxMixin from './mixins/box'
import '../src/scss/index/common.scss'

import BaiduMap from 'vue-baidu-map';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(BaiduMap, {
  ak: 'X5W6fAa8ByTtyUnZj7oOznd4qE5LFGS7'
})
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.config.productionTip = false
Vue.mixin(boxMixin)
Vue.component('my-farme', myFrame)
Vue.prototype.$global = global;
Vue.prototype.$echarts = echarts
Vue.prototype.$tracking = tracking
Vue.use(ElementUI)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
