import { render, staticRenderFns } from "./pieChart.vue?vue&type=template&id=4efb3a42&scoped=true&"
import script from "./pieChart.vue?vue&type=script&lang=js&"
export * from "./pieChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efb3a42",
  null
  
)

export default component.exports