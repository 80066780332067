<template>
  <div ref="myChart" style="width: 100%; height: 100%" id="myChart"></div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          title: {
            text: "电梯运行频率",
            textStyle: {
              color: "#00FFFF",
              fontSize: 14, //主标题文字的字体大小
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            //图例组件
            data: [
              //item
              {
                name: "工作日",
                // 强制设置图形为圆。
                icon: "circle",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#2D8AFD",
                },
              },
              {
                name: "周末",
                // 强制设置图形为圆。
                icon: "circle",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#39D371",
                },
              },
            ],
            left: 150,
            top: 7,
            //orient: "vertical",//竖向排列,默认横向
          },
          grid: [
            {
              top: "30%",
              bottom: "10%",
            },
          ],
          xAxis: {
            type: "category", //类目轴，适用于离散的类目数据
            data: [
              "0",
              "2a",
              "4a",
              "6a",
              "8a",
              "10a",
              "12a",
              "14a",
              "16a",
              "18a",
              "20a",
            ],
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },

            axisLine: {
              //轴线
              show: false,
            },
            axisTick: {
              //刻度
              show: false,
              // alignWithLabel: true,
              // length: 6,
              // lineStyle: {
              //   type: "dashed",
              // },
            },
            itemStyle: {
              show: true,
            },
          },
          yAxis: {
            type: "value", //数值轴，适用于连续数据
            max: 600,
            name: "数量/次",
            nameTextStyle: {
              color: "#CCCCCC",
            },
            axisLine: {
              show: false,
            },
            //横向网格线设置
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#0E6E5E"],
                type: "dashed",
              },
              width: 1,
            },
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },
          },
          series: [
            {
              showSymbol: true, //是否默认展示圆点
              smooth: true,
              name: "工作日",
              data: [550, 230, 224, 218, 135, 147, 200, 135, 447, 260,231],
              type: "line",
              symbol: "circle", //设定为实心点
              color: "#2D8AFD", //圆点颜色
              symbolSize: 1,
              lineStyle: {
                width: 1,
                color: "#2D8AFD", //折线颜色
              },
            },
            {
              showSymbol: true,
              smooth: true,
              name: "周末",
              data: [250, 130, 224, 318, 115, 437, 60, 135, 147, 460,200],
              type: "line",
              symbol: "circle", //设定为实心点
              color: "#39D371", //圆点颜色
              symbolSize: 1,
              lineStyle: {
                width: 1,
                color: "#39D371", //折线颜色
              },
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
