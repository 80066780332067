<template>
  <div ref="myChart" style="width: 100%; height: 100%" id="myChart"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          title: {
            //标题组件，包含主标题和副标题。
            show: false, //是否显示标题组件
            // text: "监控设备统计", //主标题文本，支持使用 \n 换行。
            // textStyle: {
            //   color: "#00FFFF", //主标题文字的颜色。
            //   fontSize: 14, //主标题文字的字体大小
            // },
            // left: 0,
            // top: 0,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            top: "15%",
            bottom: "20%",
          },
          xAxis: [
            {
              type: "category",
              data: [
                "1月",
                "2月",
                "3月",
                "4月",
                "5月",
                "6月",
                "7月",
                "8月",
                "9月",
                "10月",
                "11月",
                "12月",
              ],
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                //坐标轴刻度标签
                show: true,
                color: "#CCCCCC",
              },
              axisLine: {
                //轴线
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "value", //数值轴，适用于连续数据
              max: 6000,
              name: "数量/次",
              nameTextStyle: {
                color: "#CCCCCC",
              },
              axisLine: {
                show: false,
              },
              //横向网格线设置
              splitLine: {
                show: true,
                lineStyle: {
                  color: ["#0E6E5E"],
                  type: "dashed",
                },
                width: 1,
              },
              axisLabel: {
                //坐标轴刻度标签
                show: true,
                color: "#CCCCCC",
              },
            },
          ],
          series: [
            {
              name: "告警次数",
              type: "bar",
              barWidth: "40%",
              data: [
                1000, 2000, 5200, 2000, 3304, 5500, 3300, 2200, 2000, 3304,
                5500, 3300,
              ],
              itemStyle: {
                //图形样式
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      //颜色渐变
                      offset: 0,
                      color: "#04EFE6",
                    },
                    {
                      offset: 1,
                      color: "#008CEA",
                    },
                  ]),
                },
              },
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
