<template>
  <div ref="myChart" style="width: 100%; height: 100%" id="myChart"></div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      data: [
        { value: 66, name: "已取消 5%" },
        { value: 835, name: "按时完成 70%" },
        { value: 460, name: "待处理 35%" },
      ],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          title: {
            //标题组件，包含主标题和副标题。
            show: true, //是否显示标题组件
            text: "消防设备统计", //主标题文本，支持使用 \n 换行。
            textStyle: {
              color: "#00FFFF", //主标题文字的颜色。
              fontSize: 14, //主标题文字的字体大小
            },
            left: 0,
            top: 0,
          },
          tooltip: {
            trigger: "item", // 触发机制，鼠标悬浮圆环项上
            show: true, // 控制鼠标悬浮是否显示数据
          },
          graphic: [
            // 写了两个文字插入是因为业务要求两行不一样样式的字
            {
              //环形图中间添加文字
              type: "text", //通过不同top值可以设置上下显示
              left: "30%",
              top: "40%",
              style: {
                text: "事件总量",
                textAlign: "center",
                fill: "#fff", //文字的颜色
                fontSize: 12,
              },
            },
            {
              //环形图中间添加文字
              type: "text", //通过不同top值可以设置上下显示
              left: "32%",
              top: "52%",
              style: {
                text: "1258",
                textAlign: "center",
                fill: "#f8f8f8", //文字的颜色
                fontSize: 16,
              },
            },
          ],
          legend: {
            //图例组件
            show: false, //显示
          },
          color: [
            // 控制圆环图的颜色
            "#3D4BF1",
            "#01FCB5",
            "#FF7A01"
          ],
          series: [
            {
              name: "异常告警统计",
              type: "pie", // 类型为饼图
              center: ["40%", "50%"], // 调整圆环图位置
              radius: ["50%", "65%"], //圆环
              //   avoidLabelOverlap: true, // 防止牵引线堆叠挤在一块
              label: {
                //圆环内文字样式
                show: false, //显示
                // position: "center", // 另有参数inside，可以让数据在圆环上
                // formatter: "{c}", //模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比
                // color: "#fff",
                // fontSize: 18,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              data: this.data, // 饼图的数据，一般是发请求获取的
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
