<template>
  <div ref="myChart" style="width: 100%; height: 100%" id="myChart"></div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            //图例组件
            data: [
              //item
              {
                name: "待处理",
                // 强制设置图形为圆。
                icon: "circle",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },

                //图例项的图形样式
                itemStyle: {
                  color: "#FF7A01",
                },
              },
              {
                name: "已处理",
                // 强制设置图形为圆。
                icon: "circle",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#01FCB5",
                },
              },
              {
                name: "已取消",
                // 强制设置图形为圆。
                icon: "circle",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#3D4BF1",
                },
              },
            ],
            itemWidth: 8,
            itemHeight: 8,
            left: 123,
            top: 0,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            top: "18%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            max: 600,
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },
            //纵向网格线设置
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            type: "category",
            data: ["1月", "2月", "3月", "4月", "5月", "6月"],
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },
           
          },
          series: [
            {
              name: "待处理",
              type: "bar",
              stack: "total",
              color: "#FF7A01",
              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: [222, 182, 191, 123, 219, 133, 131],
            },
            {
              name: "已处理",
              type: "bar",
              stack: "total",
              color: "#01FCB5",
              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: [122, 182, 91, 123, 129, 233, 131],
            },
            {
              name: "已取消",
              type: "bar",
              stack: "total",
              color: "#3D4BF1",
              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: [122, 182, 191, 223, 129, 313, 131],
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
