<template>
  <div ref="myChart" style="width: 100%; height: 33%" id="myChart"></div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      data: [],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        var data1 = [
          {
            name: "",
            value: 50,
          },
        ];
        const option = {
          title: {
            //配置标题组件
            text: "电表报警率",
            x: "14%",
            y: 90,
            show: true, //设置是否显示标题，默认true
            //设置相对于仪表盘中心的偏移位置
            //数组第一项是水平方向的偏移，第二项是垂直方向的偏移
            offsetCenter: [120, "100%"],
            textStyle: {
              fontFamily: "黑体", //设置字体名称，默认宋体
              color: "#fff", //设置字体颜色，默认#333
              fontSize: 14, //设置字体大小，默认15
            },
          },

          series: [
            {
              type: "gauge",
              radius: "90%",
              center: ["50%", "65%"],
              startAngle: 180, //设置仪表盘起始角度，默认225
              endAngle: -0,
              axisLine: {
                //设置仪表盘轴线(轮廓线)相关配置
                lineStyle: {
                  width: 18,
                  color: [
                    [0.2, "#F76937"],
                    [0.8, "#23A9E2"],
                    [1, "#0C77D9"],
                  ],
                },
              },
              pointer: {
                show: true, //设置是否显示指针，默认true
                //设置指针长度,可以是绝对值，也可是相对于半径的百分比，默认80%
                length: "50%",
                width: 6, //设置指针宽度，默认8
                itemStyle: {
                  //设置仪表盘指针样式
                  color: "#0C77D9", //设置指针颜色，默认(auto)取数值所在的区间的颜色
                  borderWidth: 0, //设置描边线宽,默认0，为0时无描边
                  //设置柱条的描边类型,默认为实线，支持'solid'，'dashed'，'dotted'
                  borderType: "solid",
                  borderColor: "#000", //设置图形的描边颜色，默认"#000"，不支持回调函数
                  shadowBlur: 10, //设置(发光效果)图形阴影的模糊大小
                },
              },
              axisTick: {
                //设置刻度(线)样式
                distance: -18,
                length: 4,
                lineStyle: {
                  color: "#fff",
                  width: 2,
                },
              },
              splitLine: {
                //设置分隔线样式
                distance: -15,
                length: 15,
                lineStyle: {
                  color: "#fff",
                  width: 2,
                },
              },
              axisLabel: {
                //设置刻度标签
                show: true,
                color: "auto", //设置文字的颜色
                distance: -20, //设置标签与刻度线的距离，默认5,负数在表盘外侧
                fontSize: 10, //设置文字的字体大小，默认5
              },

              detail: {
                valueAnimation: true,
                offsetCenter: [40, "40%"],  //设置相对于仪表盘中心的偏移位置
                formatter: "{value} %",
                color: "#00FFFF",
                fontSize: 14,
              },
              data: data1,
            },
          ],
        };
        // setInterval(function () {
        //   myChart.setOption({
        //     series: [
        //       {
        //         data: [
        //           {
        //             value: +(Math.random() * 100).toFixed(2),
        //           },
        //         ],
        //       },
        //     ],
        //   });
        // }, 2000);
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
