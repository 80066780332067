<template>
  <div class="childPage">
    <div class="topTitle">报事报修</div>
    <div class="concent">
      <div class="leftCon">
        <myPieChart></myPieChart>
      </div>
      <div class="rightCon">
        <myLineChart></myLineChart>
      </div>
    </div>
  </div>
</template>
<script>
import myPieChart from "./components/repair/pieChart.vue";
import myLineChart from "./components/repair/lineChart.vue";
export default {
  components: { myPieChart, myLineChart },
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
.childPage {
  position: relative;
  width: 100%;
  height: 100%;
  .topTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 264px;
    height: 31px;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
  }
  .concent {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 25px;
    padding-left: 20px;
    .leftCon {
      width: 200px;
      height: 100%;
    }
    .rightCon {
      width: 300px;
      height: 100%;
    }
  }
}
</style>
