<template>
  <div class="childPage">
    <div class="topTitle">001龙岗值班室</div>
    <div class="concent">
      <div class="topCon">
        <div class="item item1">
          <div class="leftCon"></div>
          <div class="rightCon">在岗</div>
        </div>
        <div class="item item2">
          <div class="leftCon"></div>
          <div class="rightCon">脱岗</div>
        </div>
        <div class="item item3">
          <div class="leftCon"></div>
          <div class="rightCon">离线</div>
        </div>
      </div>
      <div class="bottomCon">
        
        <div class="item item2">
          <div class="item-top">
            <img src="@/assets/images/indexCon/robot_img.png" alt="" />
            <div class="nickName">
              <div class="leftCon onLine"></div>
              <div class="rightCon">1号哨兵</div>
            </div>
          </div>
          <div class="item-bottom">
            <p class="username">智能勤务兵</p>
            <p class="time">(00:00 ~ 24:00)</p>
          </div>
        </div>
        <!--  -->
        <div class="item item1" v-for="item in dataList" :key="item.id">
          <div class="item-top">
            <img :src="item.avatar" alt="" />
            <div class="nickName">
              <div class="leftCon onLine" v-if="item.is_online == 1"></div>
              <div
                class="leftCon noOnLine"
                v-else-if="item.is_online == 0"
              ></div>
              <div class="rightCon">2号哨兵</div>
            </div>
          </div>
          <div class="item-bottom">
            <p class="username">{{ item.account }}({{item.role_name}})</p>
            <p class="time">(00:00 ~ 24:00)</p>
          </div>
        </div>


        <!-- <div class="item item2">
          <div class="item-top">
            <img src="@/assets/images/indexCon/robot_img.png" alt="" />
            <div class="nickName">
              <div class="leftCon"></div>
              <div class="rightCon">2号哨兵</div>
            </div>
          </div>
          <div class="item-bottom">
            <p class="username">张志平（领班员）</p>
            <p class="time">(00:00 ~ 24:00)</p>
          </div>
        </div> -->
        <!-- <div class="item item3">
          <div class="item-top">
            <img src="@/assets/images/indexCon/robot_img.png" alt="" />
            <div class="nickName">
              <div class="leftCon"></div>
              <div class="rightCon">2号哨兵</div>
            </div>
          </div>
          <div class="item-bottom">
            <p class="username">李小东（值班员）</p>
            <p class="time">(00:00 ~ 24:00)</p>
          </div>
        </div> -->
        <!-- <div class="item item4">
          <div class="item-top">
            <img src="@/assets/images/indexCon/robot_img.png" alt="" />
            <div class="nickName">
              <div class="leftCon"></div>
              <div class="rightCon">2号哨兵</div>
            </div>
          </div>
          <div class="item-bottom">
            <p class="username">丁林（当班主管）</p>
            <p class="time">(00:00 ~ 24:00)</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import indexHttps from "../../https/index/index";
export default {
  name: "",
  data() {
    return {
      dataList: [],
      formInline: {
        offset: 1,
        limit: 10,
      },
    };
  },
  created() {
    this.getDutyUserList();
  },
  computed: {},
  methods: {
    getDutyUserList() {
      let that = this;
      indexHttps.dutyUser(that.formInline).then(function (res) {
        if (res.data.code == 200) {
          that.dataList = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang='scss'  scoped>
.childPage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0 14px 18px;
  .topTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 264px;
    height: 31px;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
  }
  .concent {
    width: 100%;
    .topCon {
      display: flex;
      justify-content: end;
      width: 100%;
      height: 14px;
      line-height: 14px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40px;
        margin-right: 16px;
        &:nth-child(1) .leftCon {
          background: #05d54e;
        }
        &:nth-child(2) .leftCon {
          background: #f7517f;
        }
        &:nth-child(3) .leftCon {
          background: #136be3;
        }
        .leftCon {
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
        .rightCon {
          font-size: 12px;
        }
      }
    }
    .bottomCon {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 160px;
      margin-top: 12px;
      .item {
        height: 100%;
        .item-top {
          position: relative;
          width: 84px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
          }
          .nickName {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 20px;
            // background: #1faba3;
            background: #1a3734;
            opacity: 0.9;
            text-align: center;
            .leftCon {
              width: 6px;
              height: 6px;
              border-radius: 50%;
            }
            .rightCon {
              height: 100%;
              line-height: 20px;
            }
            .onLine {
              background: #05d54e;
            }
            .noOnLine {
              background: #136be3;
            }
          }
        }
        .item-bottom {
          font-size: 12px;
          .username {
            text-align: center;
            margin-top: 11px;
          }
        }
      }
    }
  }
}
</style>
