<template>
  <div ref="myChart" style="width: 100%; height: 100%" id="myChart"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          title: {
            text: "水电气用能告警数据统计",
            textStyle: {
              color: "#00FFFF",
              fontSize: 14, //主标题文字的字体大小
            },
            top: "5%",
            left: "8%",
          },
          legend: {
            //图例组件
            data: [
              //item
              {
                name: "电表",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },

                //图例项的图形样式
                itemStyle: {
                  color: "#008CEA",
                },
              },
              {
                name: "水表",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#F6E636",
                },
              },
              {
                name: "燃气表",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#F45946",
                },
              },
            ],
            itemWidth: 12,
            itemHeight: 2,
            left: 123,
            top: 40,
          },
          tooltip: {},
          grid: {
            top: "20%",
            bottom: "15%",
            left: "20%",
            right: "5%",
          },

          xAxis: [
            {
              type: "category",
              data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月","8月"],
            },
          ],
          yAxis: {
            type: "value", //数值轴，适用于连续数据
            max: 3500,
            name: "",
            nameTextStyle: {
              color: "#CCCCCC",
            },
            axisLine: {
              show: false,
            },
            //横向网格线设置
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#0E6E5E"],
                type: "dashed",
              },
              width: 1,
            },
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },
          },

          series: [
            {
              name: "电表",
              type: "bar",
              barWidth: "20%",
              data: [3200, 522, 1301, 1334, 2390, 2330, 1320,2234],
              itemStyle: {
                //图形样式
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      //颜色渐变
                      offset: 0,
                      color: "#008CEA",
                    },
                    {
                      offset: 1,
                      color: "#04EFE6",
                    },
                  ]),
                },
              },
            },
            {
              name: "水表",
              type: "bar",
              barWidth: "20%",
              data: [2220, 2132, 3101, 1234, 1190, 511, 2210,3234],
              itemStyle: {
                //图形样式
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      //颜色渐变
                      offset: 0,
                      color: "#F6E636",
                    },
                    {
                      offset: 1,
                      color: "#EEFBA9",
                    },
                  ]),
                },
              },
            },
            {
              name: "燃气表",
              type: "bar",
              barWidth: "20%",
              data: [1220, 1823, 2191, 3234, 2290, 530, 810,1234],
              itemStyle: {
                //图形样式
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      //颜色渐变
                      offset: 0,
                      color: "#F45946",
                    },
                    {
                      offset: 1,
                      color: "#FEBBA3",
                    },
                  ]),
                },
              },
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
