<template>
  <baidu-map
    class="bm-view"
    :center="center"
    :zoom="zoom"
    :scroll-wheel-zoom="true"
    @ready="handler"
  >
    <bm-marker
      v-for="item in communitList"
      :key="item.id"
      :position="{ lng: item.lon, lat: item.lat }"
      :icon="{
        url: require('@/assets/images/indexCon/map_point_green.png'),
        size: { width: 24, height: 24 },
      }"
    >
      <bm-label
        :content="item.community_name"
        :labelStyle="{
          color: '#fff',
          fontSize: '18px',
          background: '',
          border: 0,
        }"
        :offset="{ width: -15, height: 20 }"
      />
    </bm-marker>
  </baidu-map>
</template>
<script>
import indexHttps from "@/https/index/index";
export default {
  name: "",
  data() {
    return {
      formInline: {
        offset: 1,
        limit: 10,
      },
      communitList: [],
      center: { lng: 114.34253, lat: 30.49984 }, //经纬度
      zoom: 14, //地图展示级别
    };
  },
  created() {
    // this.getCommunitList();
  },
  mounted() {
    this.getCommunitList();
  },
  methods: {
    getCommunitList() {
      let that = this;
      indexHttps.getCommunit(that.formInline).then(function (res) {
        if (res.data.code == 200) {
          that.communitList = res.data.data;
          that.center.lng = that.communitList[0].lon;
          that.center.lat = that.communitList[0].lat;
        }
      });
    },
    handler({ BMap, map }) {
      map.addControl(new BMap.NavigationControl());
      //添加地图类型控件
      map.addControl(new BMap.MapTypeControl());
      map.setMapStyleV2({
        styleId: "09723701fbd5c73dffe6eb0f3de8e980",
      });
    },
  },
};
</script>
<style lang='scss'  scoped>
::v-deep.bm-view {
  width: 100%;
  height: 100%;
  .anchorTL {
    display: none;
  }
  .anchorTR {
    display: none;
  }
  .anchorBL {
    display: none;
  }
}
</style>
