<template>
  <div class="childPage">
    <div class="topTitle">环境能耗</div>
    <div class="concent">
      <div class="leftCon">
        <myEleChart class="item"></myEleChart>
        <myWaterChart class="item"></myWaterChart>
        <myGasChart class="item"></myGasChart>
      </div>
      <div class="rightCon">
        <myLineChart></myLineChart>
      </div>
    </div>
  </div>
</template>
<script>
import myEleChart from "./components/envtConsume/eleGaugeChart.vue";
import myWaterChart from "./components/envtConsume/waterGaugeChart.vue";
import myGasChart from "./components/envtConsume/gasGaugeChart.vue";
import myLineChart from "./components/envtConsume/barChart.vue";
export default {
  components: { myEleChart,myWaterChart,myGasChart, myLineChart },
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
.childPage {
  position: relative;
  width: 100%;
  height: 100%;
  .topTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 264px;
    height: 31px;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
  }
  .concent {
    display: flex;
    height: 100%;
    padding-top: 25px;
    padding-left: 20px;
    .leftCon {
      width: 180px;
      height: 100%;
      .item {
        div {
          height: 120%;
        }
      }
    }
    .rightCon {
      width: 370px;
      height: 100%;
    }
  }
}
</style>
