<template>
  <div ref="myChart" style="width: 150%; height: 150%" id="myChart"></div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      data: [
        { value: 90, name: "电梯健康指数" },
        { value: 10, name: "" },
      ],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          title: {
            show: false, //是否显示标题组件
            // text: "电梯健康指数统计",
            // textStyle: {
            //   color: "#00FFFF", //主标题文字的颜色
            //   fontSize: 14, //主标题文字的字体大小
            // },
            // left: 0,
            // top: 0,
          },
          tooltip: {
            trigger: "item", // 触发机制，鼠标悬浮圆环项上
            show: true, // 控制鼠标悬浮是否显示数据
          },
          legend: {
            //图例组件
            show: false, //显示
          },
          // graphic: [
          //   {
          //     //环形图中间添加文字
          //     type: "text", //通过不同top值可以设置上下显示
          //     left: "30%",
          //     top: "50%",
          //     style: {
          //       text: "90",
          //       textAlign: "center",
          //       fill: "#f8f8f8", //文字的颜色
          //       fontSize: 16,
          //     },
          //   },
          // ],
          color: [
            // 控制圆环图的颜色
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#0EB58B", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#55FA6B", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            "rgba(0, 0, 0, 0)",
          ],
          series: [
            {
              name: "电梯健康指数",
              type: "pie", // 类型为饼图
              center: ["40%", "50%"], // 调整圆环图位置
              radius: ["40%", "70%"], //圆环
              avoidLabelOverlap: true, // 防止牵引线堆叠挤在一块
              label: {
                //圆环内文字样式
                show: true, //显示
                position: "center", // 另有参数inside，可以让数据在圆环上
                formatter: "{d}", //模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比
                color: "#fff",
                fontSize: 18,
              },
              data: this.data, // 饼图的数据，一般是发请求获取的
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
