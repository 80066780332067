<template>
  <div class="childPage">
    <div class="topTitle">电梯运维</div>
    <div class="concent">
      <div class="leftCon">
        <div class="left-topCon">
          <div class="chart-left">
            <p>电梯健康指数统计</p>
            <img src="../../assets/images/indexCon/elevator_img.png" alt="" />
          </div>
          <div class="chart-right">
            <myPieChart></myPieChart>
          </div>
        </div>
        <div class="left-footCon">
          <div class="item item1">
            <img
              src="../../assets/images/indexCon/elevator_zhengchang.png"
              alt=""
            />
            <span>1287</span>
          </div>
          <div class="item item2">
            <img
              src="../../assets/images/indexCon/elevator_yichang.png"
              alt=""
            />
            <span>2</span>
          </div>
          <div class="item item3">
            <img
              src="../../assets/images/indexCon/elevator_jianxiu.png"
              alt=""
            />
            <span>4</span>
          </div>
          <div class="item item4">
            <img
              src="../../assets/images/indexCon/elevator_tingti.png"
              alt=""
            />
            <span>1</span>
          </div>
        </div>
      </div>
      <div class="rightCon">
        <myLineChart></myLineChart>
      </div>
    </div>
  </div>
</template>
<script>
import myPieChart from './components/elevator/pieChart.vue'
import myLineChart from './components/elevator/lineChart.vue'
export default {
  components:{myPieChart,myLineChart},
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
.childPage {
  position: relative;
  width: 100%;
  height: 100%;
  .topTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 264px;
    height: 31px;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
  }
  .concent {
    display: flex;
    .leftCon {
      width: 200px;
      .left-topCon {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 28px;
        .chart-left {
          width: 130px;
          height: 160px;
          margin-left: 4px;
          p {
            color: #00ffff;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .chart-right {
          width: 80px;
          height: 80px;
          margin-top: 42px;
        }
      }
      .left-footCon {
        width: 100%;
        height: 100px;
        margin-left: 20px;
        .item {
          position: relative;
          float: left;
          width: 90px;
          height: 44px;
          margin-right: 10px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
          }
          span {
            position: absolute;
            top: 23px;
            right: 10px;
          }
        }
      }
    }
    .rightCon {
      width: 100%;
      height: 240px;
      // background-color: #fff;
      margin-top: 50px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
</style>
