// import Vue from 'vue'
import axios from 'axios';
import router from 'vue-router'
import { Loading } from 'element-ui'

// const __vue = new Vue();

let _loadding = null

function showLoading() {
	_loadding = Loading.service({
		text: "加载中...请稍后...",
		fullscreen: true,
		background: 'rgba(13,24,47,0.7)'
	})
}

function hideLoading() {
	_loadding.close();
}

var service = '';

//http://192.168.1.220:8801/api/documentation
if (location.hostname === 'localhost') {
	service = axios.create({
		baseURL: '/httpUrl',
		withCredentials: true // 允许携带cookie 
	});
} else {
	// var baseurl = process.env.BASE_URL   
	service = axios.create({
		baseURL: '/ops',
		withCredentials: true
	});
}


/****** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(config => {
	showLoading();
	var token = localStorage.getItem("token");
	//  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
	config.headers.Authorization = token;
	//  var token = localStorage.getItem('Authorization')
	return config;
}, error => {  //请求错误处理
	console.log(error);
	Promise.reject(error);
});


/****** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
	response => {  //成功请求到数据
		hideLoading()
		//console.log("请求数据成功");
		//这里根据后端提供的数据进行对应的处理
		if (response.data.message === '成功') {
			return response;
		} else {
			return response;
		}
	},
	error => {  //响应错误处理  
		hideLoading()
		if (error.response) {
			if (error.response.status == 500) {
				return Promise.reject(error.response)
			} else if (error.response.status == 403) {
				return Promise.reject(error.response)
			} else if (error.response.status == 401) {
				setTimeout(function () {
					router.replace({
						path: '/login'
					}).catch(err => err)
				}, 1000);
			} else {
				//接口400     
				return error.response;
			}
		}
	}
);


export default service;