import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    redirect: '/index',
    component: Layout,
    children: [{
      path: 'index',
      component: () => import('../views/index/index.vue'),
    }]
  },
  {
    path: "/second",
    component: () => import("../views/second/index.vue"),
  },
  {
    path: "/taskList",
    component: () => import("../layout/taskList.vue"),
  },
  {
    path: "/checkList",
    component: () => import("../layout/checkList.vue"),
  },
  {
    path: "/login1",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/login",
    component: () => import("../layout/faceLogin.vue"),
  },
  {
    path: "/facelogin",
    component: () => import("../layout/faceLogin2.vue"),
  },
  {
    path: "/login2",
    component: () => import("../layout/accountLogin.vue"),
  },
];

const router = new VueRouter({
  routes,
});
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
//白名单
router.beforeEach((to, from, next) => {
  const whiteRouter = ['/login','/login1',"/facelogin","/login2"];   //indexOf方法，判断数组中是否存在指定的某个对象，如果不存在，则返回-1
  if (to.path === "/login") {
    next();
  } else {
    let token = localStorage.getItem("token");
    if (token === null || token === "") {
      if (whiteRouter.indexOf(to.path) !== -1) {
        next(); 
      } else {
        next('/login'); 
      }
      // next("/login");
    } else {
      next();
    }
  }
})
export default router;
