<template>
  <div ref="myChart" style="width: 100%; height: 100%" id="myChart"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {
    this.setMyEchart();
  },
  methods: {
    setMyEchart() {
      const myChart = this.$refs.myChart; //通过ref获取到DOM节点
      if (myChart) {
        const thisChart = this.$echarts.init(myChart); //利用原型调取Echarts的初始化方法
        const option = {
          title: {
            text: "消防报警统计",
            textStyle: {
              color: "#00FFFF",
              fontSize: 14, //主标题文字的字体大小
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            //图例组件
            data: [
              //item
              {
                name: "烟感",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },

                //图例项的图形样式
                itemStyle: {
                  color: "#E1892F",
                },
              },
              {
                name: "气感",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#48BC77",
                },
              },
              {
                name: "电气火灾",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#2D8AFD",
                },
              },
              {
                name: "室内水压",
                // 强制设置图形为圆。
                icon: "rect",
                // 设置文本颜色
                textStyle: {
                  color: "#FFFFFF",
                },
                //图例项的图形样式
                itemStyle: {
                  color: "#F7517F",
                },
              },
            ],
            itemWidth: 12,
            itemHeight: 2,
            left: 5,
            top: 22,
          },
          grid: [
            {
              left: "3%",
              right: "3%",
              top: "35%",
              bottom: "0",
              containLabel: true,
            },
          ],
          xAxis: {
            type: "category", //类目轴，适用于离散的类目数据
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },
            axisLine: {
              //轴线
              show: false,
            },
            axisTick: {
              //刻度
              show: false,
            },
            itemStyle: {
              show: true,
            },
          },
          yAxis: {
            type: "value", //数值轴，适用于连续数据
            max: 600,
            name: "数量/次",
            nameTextStyle: {
              color: "#CCCCCC",
            },
            axisLine: {
              show: false,
            },
            //横向网格线设置
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#0E6E5E"],
                type: "dashed",
              },
              width: 1,
            },
            axisLabel: {
              //坐标轴刻度标签
              show: true,
              color: "#CCCCCC",
            },
          },
          series: [
            {
              name: "烟感",
              type: "line",
              // stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#E1892F",
                  },
                  {
                    offset: 1,
                    color: "#E1892F",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [
                140, 232, 401, 264, 340, 250, 264, 340, 250, 264, 590, 340,
              ],
            },
            {
              name: "气感",
              type: "line",
              // stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#48BC77",
                  },
                  {
                    offset: 1,
                    color: "#48BC77",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [
                401, 264, 340, 120, 282, 401, 234, 220, 340, 310, 511, 234,
              ],
            },
            {
              name: "电气火灾",
              type: "line",
              // stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#2D8AFD",
                  },
                  {
                    offset: 1,
                    color: "#2D8AFD",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [
                320, 132, 201, 334, 590, 130, 220, 132, 201, 334, 401, 264,
              ],
            },
            {
              name: "室内水压",
              type: "line",
              // stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FF366E",
                  },
                  {
                    offset: 1,
                    color: "#F7517F",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: [
                220, 402, 231, 534, 401, 264, 340, 190, 230, 520, 402, 231,
              ],
            },
          ],
        };
        thisChart.setOption(option); //将编写好的配置项挂载到Echarts上
        window.addEventListener("resize", function () {
          thisChart.resize(); //页面大小变化后Echarts也更改大小
        });
      }
    },
  },
};
</script>
<style lang='scss'  scoped>
</style>
