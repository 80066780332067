<template>
  <div class="headInfoPage">
    <div class="leftTime">
      <div class="year">2020.01.09</div>
      <div class="hour">14:14:36</div>
    </div>
    <div class="leftCon">
    </div>
    <div class="bodyCon">
      <div class="img">
        <img src="@/assets/images/index/top_logo.png" alt="" />
      </div>
      <div class="txt">远程守护值勤平台</div>
    </div>
    <div class="rightCon">
    </div>
    <div class="userInfo">
      <div class="img">
        <img src="@/assets/images/index/user_img.png" alt="" />
      </div>
    </div>
    <div class="layout" @click="layout">
      <img src="@/assets/images/index/exit_button.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    //退出账号
    layout() {
      this.$confirm("确认退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "confirm",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          localStorage.removeItem("token");
          this.$router.push("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>
<style lang='scss'  scoped>
.headInfoPage {
  position: relative;
  height: 100%;
  background: url(../../assets/images/index/title_di.png) no-repeat;
  .leftTime {
    position: absolute;
    left: 80px;
    top: 16px;
    width: 70px;
    height: 100%;
    .year {
      font-size: 14px;
      color: #00ffff;
      line-height: 9px;
    }
    .hour {
      font-size: 18px;
      color: #00ffff;
      line-height: 28px;
    }
  }
  .leftCon {
    position: absolute;
    left: 10%;
    top: 16px;
    display: flex;
    justify-content: space-between;
    width: 500px;
    height: 100%;
    padding-top: 5px;

    .item {
      color: #00ffff;
      span {
        color: #fff;
      }
    }
  }
  .bodyCon {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    width: 290px;
    display: flex;
    justify-content: space-between;
    .img {
      width: 86px;
      height: 40px;
      img {
        width: 100%;
      }
    }
    .txt {
      width: 190px;
      height: 20px;
      line-height: 35px;
      font-size: 22px;
      font-family: YouSheBiaoTiHei;
      color: #feeb1a;
    }
  }
  .rightCon {
    position: absolute;
    left: 65%;
    top: 16px;
    display: flex;
    justify-content: space-between;
    width: 450px;
    height: 100%;
    padding-top: 5px;
    .item {
      color: #00ffff;
      span {
        color: #fff;
      }
    }
  }
  .userInfo {
    position: absolute;
    right: 59px;
    top: 11px;
    .img {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .layout {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    img {
      height: 100%;
    }
  }
}
</style>
